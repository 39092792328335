import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import _get from "lodash/get"

import { h3 } from "css/primitives"
import { If, BlogPost } from "src/components"
import "src/queries/featured_media"

import TwitterIcon from "images/svg/icons/twitter.svg"
import FacebookIcon from "images/svg/icons/facebook.svg"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"
import { BlogStructuredData } from "../components/structured-data"
import DraftLabel from "../components/DraftLabel"
import * as util from "src/util"
import { Banner } from "src/views/blog/components"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import * as article from "src/css/article"

const wrapper = css`
  padding-bottom: 100px;
  padding-top: 4rem;
  background-color: #fafafa;
  @media (max-width: 767px) {
    padding-top: 2.5rem;
  }
  @media (max-width: 479px) {
    padding-bottom: 75px;
  }
`

const wrapperInner = css`
  margin-bottom: 3rem;
  max-width: 732px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 479px) {
    margin-bottom: 50px;
  }
`

const top = css`
  margin-bottom: 70px;
  @media (max-width: 479px) {
    margin-bottom: 2rem;
  }
`

const tagList = css`
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
`

const tagCSS = css`
  margin-right: 1.5rem;
  margin-bottom: 1rem;
  text-decoration: none;
`

const postWidth = 336
const postGutterRight = 45

const blogList = css`
  list-style-type: none;
  margin-left: 0;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-bottom: calc(90px - 54px);
  @media (min-width: 992px) and (max-width: 1150px) {
    max-width: ${postWidth * 2 + postGutterRight}px;
  }
  @media (max-width: 991px) {
    max-width: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const continueReading = css`
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  border-top: 1px solid #e8e8e8;
  padding-top: 2rem;
  @media (min-width: 992px) and (max-width: 1150px) {
    max-width: ${postWidth * 2 + postGutterRight}px;
  }
  @media (max-width: 991px) {
    max-width: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const continueReadingTitle = [
  h3,
  css`
    color: black;
    margin-bottom: 2rem;
  `,
]

const ArticleTemplate = ({ data, location }) => {
  const post = data.wordpressPost
  const relatedPosts = _get(data, "relatedPosts.edges", [])
    .slice(0, 3)
    .map((post) => post.node)
  const categories = post.categories
  const { siteUrl } = useSiteMetadata()
  let otherMetaTags = util.compileArticleSEOFields(post, siteUrl)
  const postContent = util.replaceImageBase(post.content)
  const [imageUrl, altText] = util.computeImageAttr(post)
  return (
    <PageLayout>
      <SEO
        yoastMeta={post.yoast_meta}
        title={post.title}
        ogType="article"
        extra={otherMetaTags}
        description={post.excerpt}
      />
      <BlogStructuredData
        post={post}
        siteUrl={siteUrl}
        pathname={location?.pathname ?? ""}
      />
      <div css={wrapper}>
        <div css={wrapperInner}>
          <div css={top}>
            <DraftLabel status={post.status} />
            <h1 css={article.title}>{post.title}</h1>
            <div css={article.topRow}>
              <p css={article.byLine}>by {post.author.name}</p>
              <div css={article.socialLinks}>
                <a
                  href={`https://twitter.com/intent/tweet/?text=${post.title}&url=https://smokescreen.io/blog/${post.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share on twitter"
                >
                  <TwitterIcon width={24} height={24} />
                </a>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://smokscreen.io/blog/${post.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Share on facebook"
                >
                  <FacebookIcon width={24} height={24} />
                </a>
              </div>
            </div>
          </div>
          <If test={imageUrl}>
            <div css={article.imageWrapper}>
              <Img
                fluid={imageUrl}
                alt={altText}
                loading="eager"
                fadeIn={false}
              />
            </div>
          </If>
          <div
            dangerouslySetInnerHTML={{ __html: postContent }}
            css={article.content}
          />
          <If test={Array.isArray(categories) && categories.length}>
            <div css={tagList}>
              {categories.map((category) => {
                return (
                  <Link
                    to={`/categories/${category.slug}/`}
                    key={category.id}
                    css={tagCSS}
                  >
                    #{category.name}
                  </Link>
                )
              })}
            </div>
          </If>
        </div>
        <If test={Array.isArray(relatedPosts) && relatedPosts.length}>
          <div css={continueReading}>
            <h3 css={continueReadingTitle}>Continue Reading</h3>
            <ul css={blogList}>
              {relatedPosts.map((post) => {
                return <BlogPost post={post} key={post.id} />
              })}
            </ul>
          </div>
        </If>
        <Banner data={data} />
      </div>
    </PageLayout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($id: String!, $relatedPostsIDs: [Int]!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      slug
      date
      modified
      status
      ...FeaturedMedia
      categories {
        id
        slug
        name
      }
      author {
        id
        name
      }
      yoast_meta {
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_redirect
        yoast_wpseo_opengraph_title
        yoast_wpseo_opengraph_description
        yoast_wpseo_metakeywords
        yoast_wpseo_metadesc
        yoast_wpseo_meta_robots_noindex
        yoast_wpseo_meta_robots_nofollow
        yoast_wpseo_meta_robots_adv
        yoast_wpseo_linkdex
        yoast_wpseo_focuskw
        yoast_wpseo_canonical
      }
    }
    relatedPosts: allWordpressPost(
      filter: { wordpress_id: { in: $relatedPostsIDs } }
    ) {
      edges {
        node {
          id
          title
          content
          excerpt
          slug
          date
          status
          modified
          wordpress_id
          ...FeaturedMedia
          author {
            id
            name
          }
        }
      }
    }
    costSectionImage: file(relativePath: { eq: "homepage/cost-image-2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 530) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
